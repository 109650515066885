import * as React from "react";
import { styledRow } from "./UpdatedTotal.module.scss";
import Row from "react-bootstrap/Row";

export const StyledRow = (props: any) => {
    return (<Row className={`${styledRow} ${props.className || ''} g-0`}>{props.children}</Row>);
};



