import React, { useState, useEffect } from "react";
import Axios from "axios";
import { wrapper, servicesList } from "./AddOns.module.scss";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { coreGet } from "../../../services/core";
import { buildFetchRatesUrl } from "../../../services/rates";
import { convertArrayToObject } from "../../../utils/helpers";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import OptionsItem from "../OptionsItem/OptionsItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resolvePriceType } from "../OptionsItem/OptionsItemHelpers";
import { guestServiceProvider } from "../../../services/ServiceProvider";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";

export const Wrapper = (props: any) => {
  return (
    <div className={`${wrapper} ${props.className} bg-light pb-2 rounded`}>
      {props.children}
    </div>
  );
};

export const ListGroupServices = ({
  room,
  roomNumber,
  expand,
  onServiceLoad,
}: any) => {
  const [openRoomCard, setOpenRoomCard] = useState(expand);
  const [noServicesFound, setNoServicesFound] = useState(false);
  const handleClickRoomCard = () => {
    setOpenRoomCard(!openRoomCard);
  };

  const handleServiceLoaded = (servicesFound: any) => {
    if (!servicesFound) {
      setNoServicesFound(true);
    }
    onServiceLoad && onServiceLoad(servicesFound);
  };

  return !noServicesFound ? (
    <ListGroup.Item
      action
      eventKey={room.id}
      as={Card}
      className="p-0 bg-light"
      id={`room-addons-${roomNumber}`}
    >
      <Card.Header
        className="d-flex flex-row border-top position-relative"
        onClick={handleClickRoomCard}
      >
        <div className="me-2 text-nowrap" id={`Room-${roomNumber}`}>
          <strong>Room {roomNumber}</strong>
        </div>
        <FontAwesomeIcon
          icon={`${openRoomCard ? "angle-up" : "angle-down"}`}
          className="icon"
        />
      </Card.Header>
      <Card.Body className={`p-0 my-0 mx-2`}>
        <ServiceOptions
          room={room}
          className={`p-0 ${!openRoomCard && "d-none"}`}
          onServiceLoad={handleServiceLoaded}
        />
      </Card.Body>
    </ListGroup.Item>
  ) : null;
};

export const ServiceOptions = ({ room, className, onServiceLoad }: any) => {
  const [services, setServices] = useState<Array<any> | null>(null);

  const [loadingServices, setLoadingServices] = useState(false);

  const dispatch = useAppDispatch();
  const checkout: any = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const checkoutRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const rateCode = room.rate && room.rate.RateCode;

  // Fetch pricing.
  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;

    const fetchServices = async (apiUrl: any) => {
      let fetchedServices = null;
      // const data = await coreGet(apiUrl, source);
      const guestService = await guestServiceProvider();
      const hotelAvailablityResponse = await guestService.getHotelAvailability(
        room.adults,
        room.children,
        room.childrenAges,
        checkout.HotelCode,
        checkout.chainId,
        checkout.Start,
        checkout.End,
        "NonRoom",
        checkout.EditReservationRate
          ? TP_REDEMPTION_RATE.includes(checkout.EditReservationRate)
            ? ""
            : checkout.EditReservationRate
          : checkout.promotionCode,
        checkout.EditReservationRate
          ? TP_REDEMPTION_RATE.includes(checkout.EditReservationRate)
            ? ""
            : checkout.EditReservationRate
          : checkout.groupCode || rateCode,
        checkout.discount || "Retail",
        null
      );
      const hotelData = hotelAvailablityResponse.hotelAvailablity?.queryHotel;
      if (hotelData !== null) {
        // fetchedServices = (Object as any).values(data)[0].Services;
        fetchedServices = hotelData.services.map((item) => ({
          Quantity: item.quantity.toString(),
          Title: item.name,
          Description: item.description,
          ServiceInventoryCode: item.code,
          ServicePricingType: item.pricingType,
          Price: {
            Rate: item.rate.toFixed(2),
            Taxes: item.taxes.toFixed(2),
            Total: item.total.toFixed(2),
          },
        }));
      }
      if (fetchedServices) {
        setServices(fetchedServices);
        onServiceLoad &&
          onServiceLoad(fetchedServices && fetchedServices.length > 0);
      }
    };
    // ASE - Availibilty Call
    (async () => {
      setServices(null);
      setLoadingServices(true);
      const apiUrl = buildFetchRatesUrl(
        checkout.Crs,
        checkout.HotelCode,
        checkout.Start,
        checkout.End,
        room.adults,
        room.children,
        checkout.EditReservationRate
          ? checkout.EditReservationRate
          : checkout.promotionCode,
        checkout.EditReservationRate
          ? checkout.EditReservationRate
          : checkout.groupCode,
        rateCode,
        room.childrenAges,
        null,
        checkout.discount
      );
      if (apiUrl) {
        await fetchServices(apiUrl);
        if (!didCancel) {
          setLoadingServices(false);
        }
      }
    })();

    return () => {
      didCancel = true;
      source.cancel(`Request cancelled for edit fetch rooms services`);
    };
  }, [rateCode]);

  useEffect(() => {
    let didCancel = false;
    if (services) {
      let servicesUpdated = false;
      const updatedRooms: any = checkoutRooms;
      checkoutRooms.forEach(function (room: any, key: number) {
        let updatedRoomServices: any = room.services || null;
        const originalSelectedServices = checkout.originalReservation.services;
        if (
          originalSelectedServices.length &&
          services.length &&
          updatedRoomServices == null
        ) {
          originalSelectedServices.map((originalSS: any) => {
            services.map((service: any) => {
              if (
                originalSS.serviceInventoryCode === service.ServiceInventoryCode
              ) {
                servicesUpdated = true;
                const priceTypeFromApi = service.ServicePricingType.toString()
                  .toLowerCase()
                  .trim()
                  .replace(/\s/g, "_");
                const priceTypes = resolvePriceType(priceTypeFromApi);
                const nights = room?.rate?.roomRateNightly?.length || 1;
                const calculatedPrice =
                  priceTypes.indexOf("per_night") !== -1
                    ? +originalSS.price.rate * nights
                    : +originalSS.price.rate;
                const calculatedTaxes =
                  priceTypes.indexOf("per_night") !== -1
                    ? +originalSS.price.taxes * nights
                    : +originalSS.price.taxes;
                const _newService = {
                  [service.ServiceInventoryCode]: {
                    Description: service.Description,
                    ServiceRPH: service.ServiceRPH,
                    ServiceInventoryCode: service.ServiceInventoryCode,
                    ServicePricingType: service.ServicePricingType,
                    Price: calculatedPrice,
                    Tax: calculatedTaxes,
                    AvailedQuantity: +originalSS.quantity,
                    Title: service?.Title,
                  },
                };
                updatedRoomServices = {
                  ...updatedRoomServices,
                  ..._newService,
                };
              }
            });
          });
        } else {
          !room.services &&
            services.forEach((service: any) => {
              servicesUpdated = true;
              if (Math.ceil(service.Price.Rate) === 0) {
                const _newService = {
                  [service.ServiceInventoryCode]: {
                    Description: service.Description,
                    ServiceRPH: service.ServiceRPH,
                    ServiceInventoryCode: service.ServiceInventoryCode,
                    ServicePricingType: service.ServicePricingType,
                    Price: 0,
                    Tax: 0,
                    AvailedQuantity: 1,
                    Title: service?.Title,
                  },
                };
                updatedRoomServices = {
                  ...updatedRoomServices,
                  ..._newService,
                };
              }
            });
        }
        updatedRooms[key] = { ...room, services: updatedRoomServices };
      });
      const newCheckout = {
        ...checkout,
        Rooms: convertArrayToObject(updatedRooms, "id"),
      };
      !didCancel &&
        servicesUpdated &&
        dispatch(setEditReservationCheckout(newCheckout));
    }

    return () => {
      didCancel = true;
    };
  }, [services]);

  const options =
    services &&
    services.map((service: any, idx: number) => {
      if (service.ServiceInventoryCode && service.ServiceInventoryCode.length) {
        const inventoryCode = service.ServiceInventoryCode;
        const nights = room?.rate?.RoomRateNightly?.length || 1;
        const occupancy = +room.adults + room.children;
        // const rate = room.room && room.room.rate ;
        return (
          <Col xs="12" className={`mb-2`} key={`col-${inventoryCode}`}>
            <OptionsItem
              key={`${room.id}-${inventoryCode}`}
              room={room}
              service={service}
              nights={nights}
              occupancy={occupancy}
              id={`service-${idx + 1}`}
            />
          </Col>
        );
      }
    });

  return services && services.length ? (
    <div className={`px-2 px-lg-3 ${servicesList}`}>
      <Row className={`pb-2 pt-2 ${className}`}>{options}</Row>
    </div>
  ) : null;
};
