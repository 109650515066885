import * as React from "react";
import Container from "react-bootstrap/Container";
import { container } from "./ReservationDetails.module.scss";

export const StyledContainer = (props: any) => {
    return (<Container className={container}>{props.children}</Container>);
};



