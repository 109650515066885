import React, { useState, useEffect } from "react";
import moment from "moment-mini";
import isEmpty from "lodash/isEmpty";
import { graphql, navigate } from "gatsby";
import { IEditReservationProps } from "../pageHelpers/EditReservation/EditReservationProps";
import {
  StyledContainer,
  DarkBackground,
} from "../pageHelpers/EditReservation/EditReservationHelpers";
import Layout from "../components/global/Layout/Layout";
import { setEditReservationCheckout } from "../redux/slices/Checkout/editReservationCheckout";
import { createResRequestJSON, updateReservation } from "../services/crs";
import { resParseDateFormat } from "../services/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "react-loading-overlay";
import SEO from "../components/global/SEO/SEO";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import SubTitle from "../components/global/SubTitle/SubTitle";
import AddOns from "../components/reservations/AddOns/AddOns";
import CheckoutSpecialRequest from "../components/common/CheckoutSpecialRequest/CheckoutSpecialRequest";
import CheckoutAgreement from "../components/common/CheckoutAgreement/CheckoutAgreement";
import SelectRoom from "../components/reservations/SelectRoom/SelectRoom";
import UpdatedTotal from "../components/reservations/UpdatedTotal/UpdatedTotal";
import ReservationDetails from "../components/reservations/ReservationDetails/ReservationDetails";
import CurrentTotal from "../components/reservations/CurrentTotal/CurrentTotal";
import EditOffers from "../components/reservations/EditOffers/EditOffers";
import EditCheckoutPayment from "../components/reservations/EditCheckoutPayment/EditCheckoutPayment";
import { useAppSelector, useAppDispatch } from "../hooks";
import { isLoggedInSelector } from "../redux/slices/Member/member";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { Constants, Edit_Reservation_Success } from "../@types/Constants";
import { addPageTypeInGTMDataLayer } from "../utils/datalayers";
import { useWebFramed } from "../hooks/useWebFramed";
import { PostMessage } from "../utils/webFramed";
//import { addKoddiPageTag } from "../utils/KoddiTagsGTM";

const EditReservation = (props: IEditReservationProps) => {
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const editReservationCheckout = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const step = useAppSelector(
    (state) => state.editReservationCheckout.Step || "start"
  );
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const data: { [x: string]: any } = props.location.state || {};

  const reservation = data.reservation || {};
  const hotel = data.hotel || {};
  const brandCode =
    hotel.relationships && hotel.relationships.brand_id
      ? hotel.relationships.brand_id.brand_code
      : "";
  const confirmationID = data.confirmationID;
  const originalRoom = data.room;
  const originalRate = data.rate;
  const [room, setRoom] = useState();
  const [rate, setRate] = useState();

  const guestInfo = reservation.guests && reservation.guests[0];
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [specialRequest, setSpecialRequest] = useState("");

  const [isUpdatingReservation, setIsUpdatingReservation] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");

  useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("edit reservation");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const handleClearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  const onRequestFailure = (message: string) => {
    const errorMetaData = confirmationID;
    const errorMsg = (
      <ErrorMessageAlert
        errorType={Constants.ERRORSTYPE.RESERVATION}
        errorSubType={Constants.ERRORSSUBTYPE.RESRVATION.UPDATE}
        message={`${message} -- ${errorMetaData}`}
      >
        Your reservation could not be updated. <span>{message}</span>
      </ErrorMessageAlert>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
  };

  const clearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  const handleChangeSpecialRequest = (e: any) => {
    setSpecialRequest(e.target.value);
  };

  const handleCheckAgreeTerms = () => {
    setTermsAgreed(!termsAgreed);
  };

  const handleCancelChanges = () => {
    dispatch(setEditReservationCheckout({}));
  };

  const handleReserveYourStay = () => {
    clearError();
    setIsUpdatingReservation(true);
    if (!editReservationCheckout || !editReservationCheckout.Payment) {
      onRequestFailure(
        "Oops! something went wrong. Please reload the page or try again later."
      );
      setIsUpdatingReservation(false);
    } else {
      if (!editReservationCheckout.Payment.ccToken) {
        setOverlayMessage("Verifying credit card...");

        (window as any).mycheckWallet.getCardToken().then(
          function (token: any) {
            // setNewCCToken(token);
            completeReservation(token);
          },
          function (error: any) {
            const paymentSection = document.getElementById(
              "checkoutPaymentSection"
            );
            paymentSection && paymentSection.scrollIntoView();

            onRequestFailure(error.message);
            setIsUpdatingReservation(false);
          }
        );
      } else {
        completeReservation(editReservationCheckout.Payment.ccToken);
      }
    }
  };

  const completeReservation = async (ccToken?: any) => {
    setOverlayMessage("Updating reservation...");
    let checkoutJSON = editReservationCheckout;
    if (ccToken) {
      checkoutJSON = {
        ...checkoutJSON,
        Payment: { ...editReservationCheckout.Payment, ccToken: ccToken },
      };
    }

    if (specialRequest) {
      checkoutJSON = {
        ...checkoutJSON,
        SpecialRequests: [
          {
            Text: specialRequest,
          },
        ],
      };
      // editReservationCheckout.SpecialRequests = [{
      //   Text: specialRequest
      // }];
    }
    const request = createResRequestJSON(checkoutJSON);
    request.reservation.ID = confirmationID;

    const confirmation = await updateReservation(request);
    if (!confirmation || confirmation.error || confirmation.message) {
      onRequestFailure(
        confirmation && (confirmation.message || confirmation.error)
      );
    } else {
      const confirmedReservations = [
        {
          crs_reservation_id: confirmation.ID,
          arrival: moment(confirmation.Start, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          departure: moment(confirmation.End, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          details: { ...confirmation, Crs: "synxis" },
          lastName: confirmation.ResGuests[0]?.Surname || guestInfo.Surname,
        },
      ];
      if (isWebFramed) {
        PostMessage({
          type: Edit_Reservation_Success,
          payload: {
            roomType: confirmation.RoomDescription.Name,
            roomRate: confirmation.RoomRates[0].Total,
          },
        });
      } else {
        navigate("/reservation-confirmation", {
          state: {
            reservations: confirmedReservations,
            confirmationType: "update",
          },
        });
      }
    }
    setIsUpdatingReservation(false);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (Object.keys(data).length == 0) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (step == "start") {
      setRoom(originalRoom);
      setRate(originalRate);
    } else {
      setRoom((Object as any).values(editReservationCheckout.Rooms)[0].room);
      setRate((Object as any).values(editReservationCheckout.Rooms)[0].rate);
    }
  }, [step]);

  useEffect(() => {
    if (isEmpty(editReservationCheckout)) {
      navigate(-1);
    }
  }, [editReservationCheckout]);

  return (
    !isEmpty(editReservationCheckout) && (
      <Layout brand={brandCode} noIndex={true} noFollow={true}>
        <SEO title={"Edit Reservations"} koddiTitle="Edit Reservation" />
        <h1 className="visually-hidden">Edit Reservation</h1>
        <StyledContainer>
          {showError && errorMsg && (
            <Row className="px-lg-5 mx-lg-5">
              <Col>
                <Alert
                  variant="danger"
                  className="text-center"
                  dismissible
                  onClose={handleClearError}
                >
                  {errorMsg}
                </Alert>
              </Col>
            </Row>
          )}
          {step != "select_room" && (
            <Row>
              <Col>
                <div className="aside">
                  <SubTitle>EDIT RESERVATION</SubTitle>
                  <h2>Reservation Details</h2>
                  <h3 className="mt-4">{hotel.name}</h3>
                  <div className="mb-4 conf-id">
                    <strong>Reservation ID:</strong> {confirmationID}
                  </div>
                </div>
                <div className="subtitle mb-2">Your Reservation</div>
              </Col>
            </Row>
          )}
          {(step == "start" || step == "finish") && room && (
            <>
              <Row className="g-0">
                <Col sm={12} lg={7} className="bg-light">
                  <ReservationDetails reservation={reservation} room={room} />
                  <div className="ms-3  py-2">
                    <FontAwesomeIcon icon="user-friends" className="me-3" />
                    Guest Information
                  </div>
                  <Form.Row className="mx-2 mb-4">
                    <Form.Group as={Col} xs={6} lg={4} controlId="givenName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={guestInfo.givenName}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={4} controlId="surname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={guestInfo.surname}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>E-mail Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={guestInfo.email}
                        readOnly
                      />
                    </Form.Group>
                  </Form.Row>
                  <EditOffers />
                  <AddOns />
                </Col>
              </Row>
              <Row className="g-0">
                <Col sm={12} lg={7}>
                  <Row className="mt-5 mb-4">
                    <Col>
                      <CheckoutSpecialRequest
                        setSpecialRequest={handleChangeSpecialRequest}
                      />
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <div id="checkoutPaymentSection">
                        <EditCheckoutPayment
                          className="mt-5"
                          email={guestInfo.email}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <CheckoutAgreement
                        termsAgreed={termsAgreed}
                        setAgreeTerms={handleCheckAgreeTerms}
                        editCheckout={editReservationCheckout}
                      />
                    </Col>
                  </Row>
                  <CurrentTotal
                    rooms={Object.values(editReservationCheckout.Rooms)}
                    reservation={reservation}
                  />
                  <UpdatedTotal />
                  <Row className="mt-5">
                    <Col>
                      <Button
                        className="w-100 p-2 text-uppercase"
                        disabled={!termsAgreed || !rate}
                        onClick={handleReserveYourStay}
                      >
                        Update Reservation
                      </Button>
                    </Col>
                  </Row>
                  {!isWebFramed && (
                    <Row className="mt-4 g-0">
                      <Col className="text-center">
                        <Button
                          variant="link"
                          className="text-uppercase ps-0"
                          onClick={handleCancelChanges}
                        >
                          Cancel Changes
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}
          {step == "select_room" && <SelectRoom />}
        </StyledContainer>
        <DarkBackground show={isUpdatingReservation}>
          <LoadingOverlay
            active={isUpdatingReservation}
            spinner={true}
            text={overlayMessage}
          ></LoadingOverlay>
        </DarkBackground>
      </Layout>
    )
  );
};
export const pageQuery = graphql`
  query EditReservationQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default EditReservation;
