import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { StyledRow } from "./CurrentTotalHelpers";
import { priceFormatter } from "../../../utils/helpers";
import { formatDate, parseSearchDate } from "../../../services/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment-mini';
import { ICurrentTotalProps } from "./CurrentTotalProps";
import { ITaxItem } from "../../../@types/ITaxItem";

const CheckoutTotal = ({rooms, reservation, className}: ICurrentTotalProps) => {
  // const checkoutObj = useSelector(state => state.checkout);
  const checkInDate = reservation.Start;
  const checkOutDate = reservation.End;
  const stayDates = `${formatDate(parseSearchDate(checkInDate) as Date, 'MMM D')} - ${formatDate(parseSearchDate(checkOutDate) as Date, 'MMM D, YYYY')}`;

  const roomRate = reservation.RoomRates[0];
  let taxesAndFeesTotal = 0;

  const taxItemsWise: Array<ITaxItem> = [];

  roomRate?.TaxItems && roomRate.TaxItems.length && roomRate.TaxItems.forEach((taxItem: ITaxItem) => {
      let taxItemAmount = parseFloat(taxItem.Amount);
      if(taxItem.ChargeFrequency == 1) {// per night
        const numOfNights = moment(taxItem.ExpireDate).diff(moment(taxItem.EffectiveDate), 'days') + 1;
        taxItemAmount =  taxItemAmount * numOfNights;
      }
      taxesAndFeesTotal += taxItemAmount;
      
      const existingItemindex = taxItemsWise.findIndex(item => item.Name === taxItem.Name);
      if (existingItemindex > -1) {
        taxItemsWise[existingItemindex]['Amount'] = taxItemsWise[existingItemindex]['Amount'] + taxItemAmount;
      }
      else { 
        taxItemsWise.push({...taxItem, 'Amount': taxItemAmount}); 
      }
  });

  const servicePrice = (roomRate && roomRate.Upgrades) || 0;
  const total = roomRate && parseFloat(roomRate.Total);
  const subTotal = roomRate&& parseFloat(roomRate.Subtotal);

  const nightsInDays = moment(checkOutDate, 'YYYY-MM-DD').diff(moment(checkInDate, 'YYYY-MM-DD'), 'days');
  // const nights = rooms[0].rate !== null && rooms[0].rate.RoomRateNightly.length > 1 ? `${rooms[0].rate.RoomRateNightly.length} Nights` : `1 Night`;
  const nights = nightsInDays > 1 ? `${nightsInDays} Nights` : `1 Night`;
  //setTotalAmount(total);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const currency = rooms && rooms.length && rooms[0].room ? rooms[0].room.Currency : '';

  const handleShowPriceDetails = () => {
    setShowPriceDetails(!showPriceDetails);
  };

  const handleShowTaxDetails = () => {
    setShowTaxDetails(!showTaxDetails);
  };

  const detailsIcon = !showPriceDetails ? "angle-down" : "angle-up";
  const taxDetailsIcon = !showTaxDetails ? "angle-down" : "angle-up";
  return (
    <Container className={className}>
      <StyledRow className={`pt-3 border-top `}>
        <Col className="details px-0">
          <div className="heading" id="current-total-heading">Current Total</div>
        </Col>
        {showPriceDetails ?
          <Col className="text-end total pe-0" id="current-total-cart-total">
            {priceFormatter(total)}
          </Col>
          :
          <>
            <Col xs="auto" className="text-end breakdown mb-2 mb-lg-0 me-lg-4">
              <div id="current-total-number-of-stays">
                {nights} {rooms.length > 1 ? `(${rooms.length} Rooms)` : ""}: {priceFormatter(subTotal, 2, currency)}
              </div>
              <div id="current-total-taxes-for-stays">
                Taxes & Fees: {priceFormatter(taxesAndFeesTotal, 2, currency)}
              </div>
            </Col>
            <Col className="text-end total" lg="auto" id="cartTotal">
              {priceFormatter(total, 2, currency)}
            </Col>
          </>
        }
      </StyledRow>
      {showPriceDetails &&
      <StyledRow className={`pb-3 pb-lg-0`}>
        <Col className="px-0 d-flex align-items-end">
          <div className="breakdown mt-1 w-100">
            <div className="mb-lg-0">
              <div className="fw-bold my-2">{stayDates} ({nights})</div>
              {
                rooms.map((room, idx) => {
                  if (room.rate) {

                    return <div key={`room-price${idx}`}>
                              <div className="fw-bold mt-2" id={`current-total-room-${idx+1}`}>Room {idx + 1}</div>
                              <Row>
                                <Col id="no-of-nights">{nights}</Col><Col id="cost-of-stays">{priceFormatter(subTotal, 2, currency)}</Col>
                              </Row>
                              {servicePrice > 0 &&
                                <Row>
                                  <Col>Options</Col><Col>{priceFormatter(servicePrice, 2, currency)}</Col>
                                </Row>
                              }
                            </div>;
                  }
                  return null;
                })
              }
              <div className="my-3">
                <Button
                  variant="link"
                  className="mb-1 p-0"
                  onClick={handleShowTaxDetails}
                >
                  <span className="fw-bold" id="taxes-and-fees">Taxes &amp; Fees: {priceFormatter(taxesAndFeesTotal, 2, currency)}</span>
                  <FontAwesomeIcon icon={taxDetailsIcon} className="ms-1"/>
                </Button>
                  {
                  showTaxDetails && taxItemsWise.map((tItem, idx) => {
                    if (tItem.Amount) {
                      return (
                        <div key={`tax-item${idx}`} id={`current-total-tax-item${idx+1}`}>
                          <Row>
                            <Col id="tax-item-name">{tItem.Name}</Col><Col id="tax-item-cost">{priceFormatter(parseFloat(tItem.Amount) , 2, currency)}</Col>
                          </Row>
                        </div>
                        );
                    }
                    return null;
                  })
                }
              </div>
              <div className="grand-total mb-3" id="current-total-grand-total">Grand Total: {priceFormatter(total, 2, currency)}</div>
            </div>
          </div>
        </Col>
      </StyledRow>
      }
      <StyledRow className="border-bottom pb-2">
        <Col className="p-0">
          <Button
            variant="link"
            className="mt-2 p-0 text-uppercase price-btn"
            onClick={handleShowPriceDetails}
            id={`expant-current-price-details-button`}
          >
            {!showPriceDetails ? "Expand Price Details" : "HIDE Price Details"}
            <FontAwesomeIcon icon={detailsIcon} className="ms-1"/>
          </Button>
        </Col>
      </StyledRow>
    </Container>
  );
};
export default CheckoutTotal;
