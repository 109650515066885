import * as React from "react";
import { styledRow, tooltip } from "./OptionsItem.module.scss";
import Row from "react-bootstrap/Row";

export const StyledRow = (props: any) => {
    return (<Row id={props.id} className={`${styledRow} bg-white py-2 g-0`}>{props.children}</Row>);
};

export const Tooltip = React.forwardRef((props: any, ref) => {
    return (<span id={props.id} className={tooltip} {...props} ref={ref}>{props.children}</span>);
});

export const resolvePriceType = (priceType: string) => {
    switch (priceType) {
        case 'per_stay':
        case 'per_room': {
          return (['per_stay']);
        }
        case 'per_item':
        case 'per_person': {
          return (['per_item']);
        }
        case 'per_night':
        case 'per_adult_per_night':
        case 'per_room_per_night': {
          return (['per_night']);
        }
        case 'per_person_per_night': {
          return (['per_item', 'per_night']);
        }
        default:
          return [priceType];
      }
};



